import React from 'react'
import { Layout } from '@components/LayoutEN'
import { SEO } from '@components/SEO'
// import MyTabs from '@components/Tab'
import { navigate } from 'gatsby'
import styled from '@style'
import {
  Hero,
  HeroTitle,
  Benefits,
  Program,
  ProgramDes,
} from '../screens/EuropeEN'

const StyledDiv = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

const JoinUs = () => {
  if (typeof window !== 'undefined' && window.location.pathname === '/europe') {
    navigate('/es/joinUs')
  }

  return (
    <Layout>
      <SEO title="Join Us" />
      <HeroTitle
        subtitle="Job Offer"
        titleProgram="We have the most exciting Programs that you would like to be apart of it."
        email="info@aibrok.com.mx"
        suggestion="Send us your resume to "
      />
      <>
        <Hero titleProgram="Wealth Manager" joinUs="Join Us" />
        <ProgramDes
          descriptionOne=" Would you like to work in the Fintech sector, working in one of
               those that is experiencing the greatest growth nationally and
               internationally?"
          subDescriptionOne="At Aibrok, a leading company in the Fintech sector, we are
               looking for Collaborating Agents who provide knowledge, skills
               and experience in customer management. Committed, proactive
               people who want to learn and add value."
          descriptionTwo="  We are more than 175 people in the entity, with a network of 25
              
                offices."
          subDescriptionTwo=" If you want to develop your professional career in the
               technological financial sector, this is your opportunity."
        />
        <Benefits />
      </>
    </Layout>
  )
}

export default JoinUs
